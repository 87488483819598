import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position: "relative",
    },
    modalClose: {
        position: "absolute",
        marginRight: theme.spacing(2),
        top: 0,
        right: 0,
        cursor: "pointer",
    },
}));

interface ModalProps {
    isOpen: boolean;
    close(): void;

    allowClose?: boolean;
    delayMs?: number;

    title: string;
    description: string;
}

const ModalMsg: React.FC<ModalProps> = props => {
    const classes = useStyles();

    const allowClose = props.allowClose || false;
    const timeout = props.delayMs || 400;

    return (
        <Modal
            className={classes.modal}
            open={props.isOpen}
            onClose={() => allowClose && props.close()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout }}
        >
            <Fade in={props.isOpen}>
                <div className={classes.modalPaper}>
                    {allowClose && (
                        <p onClick={props.close} className={classes.modalClose}>
                            &#10006;
                        </p>
                    )}
                    <h2>{props.title}</h2>
                    <p>{props.description}</p>
                    {props.children}
                </div>
            </Fade>
        </Modal>
    );
};

export default ModalMsg;
