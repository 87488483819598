import { fetchAndRenew } from "./shared";

export interface AttendanceStatData {
    date: Date;
    minutes: number;
    holiday: boolean;
}

export type AttendanceStatRange = "week" | "month" | "year" | "all";
export async function getAttendanceStats(range: AttendanceStatRange): Promise<AttendanceStatData[]> {
    const response = await fetchAndRenew(`/get_attendance_stats?range=${range}`, "GET");

    const data = (await response.json()) as any[];
    for (let val of data) {
        val.date = new Date(val.date * 1000);
    }

    return data as AttendanceStatData[];
}

export interface HolidayStats {
    days: number;
    usedDays: number;
    futureUsedDays: number;
}
export async function getHolidayStats(): Promise<HolidayStats> {
    const response = await fetchAndRenew("/get_holiday_stats", "GET");
    return await response.json();
}

export interface UserCount {
    allUserCount: number;
    attendantUserCount: number;
}

export async function getUserCount(): Promise<UserCount> {
    const response = await fetchAndRenew("/get_user_count", "GET");
    const userCounts = await response.text();
    const [attendantUserCount, allUserCount] = userCounts.split("/");
    return { attendantUserCount: Number(attendantUserCount), allUserCount: Number(allUserCount) };
}
