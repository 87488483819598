import React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import de from "date-fns/locale/de";

import Main from "./pages/Main";
import Login from "./pages/Login";

const theme = createMuiTheme({
    palette: {
        background: {
            default: "#faedf1",
        },
        primary: {
            main: "#dd0051",
        },
        secondary: {
            main: "#454340",
        },
    },
});

const App: React.FC = () => {
    return (
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                <CssBaseline />
                <Router>
                    <Switch>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/">
                            <Main />
                        </Route>
                    </Switch>
                </Router>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
};

export default App;
