import React, { useState, useContext, ChangeEvent, FormEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    ListItemText,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    InputLabel,
    Input,
    FormControl,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { GlobalContext } from "./Main";
import { InvalidTokenError } from "../api/shared";
import { User, addUser, updateAccount } from "../api/users";
import { Resource, error } from "../utils/resource";
import { dateToString, dateIsValid } from "../utils/date";

import { ModalState, ERR_MSG_DATE_INVALID, ERR_PASSWORD_INVALID, ERR_MSG_MISSING_FIELDS } from "./AdminPanel";

const useStyles = makeStyles(theme => ({
    mobileUserForm: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        "& > *": {
            marginBottom: theme.spacing(1),
        },
    },
}));

export interface FormUser {
    personalnummer: { isEditable: boolean; value?: number };
    firstName: { isEditable: boolean; value?: string };
    lastName: { isEditable: boolean; value?: string };
    roleID: { isEditable: boolean; value?: number };
    birth: { isEditable: boolean; value?: undefined }; // value must be handled separately
    startDate: { isEditable: boolean; value?: undefined };
}
const defaultValues = () => ({
    personalnummer: { isEditable: false },
    firstName: { isEditable: false },
    lastName: { isEditable: false },
    roleID: { isEditable: false },
    birth: { isEditable: false },
    startDate: { isEditable: false },
});

interface MobileUserProps {
    user?: User;
    setModal(m: ModalState): void;
    setUsers(u: Resource<User[]>): void;
    fetchUsers(): Promise<void>;
}
const MobileUser: React.FC<MobileUserProps> = ({ user, setModal, fetchUsers, setUsers }) => {
    const classes = useStyles();
    const { claims, showInvalidTokenModal } = useContext(GlobalContext);

    const [formUser, setFormUser] = useState<FormUser>(defaultValues());
    const [formBirth, setFormBirth] = useState<Date | null>(user?.birth ?? null);
    const [formStartDate, setFormStartDate] = useState<Date | null>(user?.startDate ?? null);
    const [formPassword, setFormPassword] = useState<{ isEditable: boolean; value?: string }>({
        isEditable: false,
        value: undefined,
    });

    const reset = () => {
        setFormUser(defaultValues());
        setFormBirth(null);
        setFormStartDate(null);
        setFormPassword({ isEditable: false });
    };

    const updateField = (e: ChangeEvent<HTMLInputElement>) => {
        setFormUser({
            ...formUser,
            [e.target.name]: { isEditable: true, value: e.target.value },
        });
    };

    const setFieldEditable = (field: keyof FormUser) => {
        setFormUser({
            ...formUser,
            [field]: { isEditable: true, value: formUser[field].value },
        });
    };

    const handleSubmit = (event: FormEvent, user: User) => {
        event.preventDefault();

        if (formPassword.value !== undefined && formPassword.value === "") {
            setModal({ type: "err", msg: ERR_PASSWORD_INVALID });
            throw new Error();
        }

        if ((formBirth && !dateIsValid(formBirth)) || (formStartDate && !dateIsValid(formStartDate))) {
            setModal({ type: "err", msg: ERR_MSG_DATE_INVALID });
            return Promise.reject();
        }

        const birth = formBirth ?? undefined;
        const startDate = formStartDate ?? undefined;

        const options = {
            newFirstName: formUser.firstName.value !== user.firstName ? formUser.firstName.value : undefined,
            newLastName: formUser.lastName.value !== user.lastName ? formUser.lastName.value : undefined,
            newBirth: birth && birth.getTime() !== user.birth.getTime() ? birth : undefined,
            newStartDate: startDate && startDate.getTime() !== user.startDate.getTime() ? startDate : undefined,
            newRoleID: formUser.roleID.value !== user.roleID ? formUser.roleID.value : undefined,
            newPassword: formPassword.value,
        };
        if (
            options.newFirstName === undefined &&
            options.newLastName === undefined &&
            options.newBirth === undefined &&
            options.newStartDate === undefined &&
            options.newRoleID === undefined &&
            options.newPassword === undefined
        ) {
            return Promise.resolve();
        }

        return updateAccount(user.personalnummer, options)
            .then(fetchUsers)
            .catch(e => {
                if (e instanceof InvalidTokenError) {
                    showInvalidTokenModal();
                } else {
                    console.error(e);
                    setUsers(error());
                }
            });
    };

    const handleDelete = (user: User) => {
        const personalnummer = user.personalnummer;
        setModal({ type: "deleteSelf", personalnummer, isSelf: personalnummer === claims.sub });
    };

    const handleUserAdd = (e: FormEvent) => {
        e.preventDefault();

        if (
            !formUser.firstName.value ||
            !formUser.lastName.value ||
            //!formUser.roleID.value ||
            !formPassword.value ||
            !formBirth ||
            !formStartDate
        ) {
            setModal({ type: "err", msg: ERR_MSG_MISSING_FIELDS });
            return;
        }

        if (!dateIsValid(formBirth) || !dateIsValid(formStartDate)) {
            setModal({ type: "err", msg: ERR_MSG_DATE_INVALID });
            return;
        }

        const options = {
            firstName: formUser.firstName.value,
            lastName: formUser.lastName.value,
            //roleID: formUser.roleID.value,
            roleID: 0,
            birth: formBirth,
            startDate: formStartDate,
            password: formPassword.value,
        };
        return addUser(options)
            .then(fetchUsers)
            .then(reset)
            .catch(e => {
                if (e instanceof InvalidTokenError) {
                    showInvalidTokenModal();
                } else {
                    console.error(e);
                    setUsers(error());
                }
            });
    };

    const adornment = (isEditable: boolean, setEditable: () => void) =>
        isEditable ? null : (
            <InputAdornment position="end">
                <IconButton onClick={setEditable} onMouseDown={e => e.preventDefault()} edge="end">
                    {<EditIcon />}
                </IconButton>
            </InputAdornment>
        );

    const textInput = (field: keyof FormUser, name: string) => (
        <FormControl fullWidth>
            <InputLabel htmlFor={field + user?.personalnummer ?? "add"}>{name}</InputLabel>
            <Input
                name={field}
                id={field + user?.personalnummer ?? "add"}
                defaultValue={user ? user[field] : undefined}
                onChange={updateField}
                disabled={user && !formUser[field].isEditable}
                endAdornment={adornment(!user || formUser[field].isEditable, () => setFieldEditable(field))}
            />
        </FormControl>
    );

    const dateInput = (
        field: keyof FormUser,
        name: string,
        set: typeof setFormBirth,
        value: Date | null,
        disableFuture = false
    ) => {
        const editable = formUser[field].isEditable;

        if (!user || editable) {
            return (
                <FormControl fullWidth>
                    <KeyboardDatePicker
                        autoOk
                        disableFuture={disableFuture}
                        variant="inline"
                        format="dd.MM.yyyy"
                        label={name}
                        value={value}
                        onChange={d => set(d)}
                        InputAdornmentProps={{ position: "end" }}
                        KeyboardButtonProps={{ edge: "end" }}
                    />
                </FormControl>
            );
        } else {
            return (
                <FormControl fullWidth>
                    <InputLabel htmlFor={field + "preview" + user?.personalnummer ?? "add"}>{name}</InputLabel>
                    <Input
                        id={field + "preview" + user?.personalnummer ?? "add"}
                        type="text"
                        defaultValue={value ? dateToString(value) : undefined}
                        disabled={true}
                        endAdornment={adornment(formUser[field].isEditable, () => setFieldEditable(field))}
                    />
                </FormControl>
            );
        }
    };

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {user && (
                    <IconButton onClick={() => handleDelete(user)} style={{ marginLeft: "-1rem" }}>
                        <DeleteIcon />
                    </IconButton>
                )}
                {user ? (
                    <ListItemText
                        primary={`${user.firstName} ${user.lastName}`}
                        secondary={`#${user.personalnummer} ${Number(user.roleID) === 1 ? "(Admin)" : ""}`}
                    />
                ) : (
                    <ListItemText primary="Nutzer hinzufügen" />
                )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <form
                    className={classes.mobileUserForm}
                    noValidate
                    autoComplete="off"
                    onSubmit={event => (user ? handleSubmit(event, user) : handleUserAdd(event))}
                >
                    {textInput("firstName", "Vorname")}
                    {textInput("lastName", "Nachname")}
                    {dateInput("birth", "Geburtstag", setFormBirth, formBirth, true)}
                    {dateInput("startDate", "Startdatum", setFormStartDate, formStartDate)}
                    <FormControl fullWidth>
                        <InputLabel htmlFor={"password" + user?.personalnummer ?? "add"}>Passwort</InputLabel>
                        <Input
                            name={"password"}
                            id={"password" + user?.personalnummer ?? "add"}
                            onChange={e =>
                                setFormPassword({ isEditable: !user || formPassword.isEditable, value: e.target.value })
                            }
                            disabled={user && !formPassword.isEditable}
                            endAdornment={adornment(!user || formPassword.isEditable, () =>
                                setFormPassword({ isEditable: true, value: formPassword.value })
                            )}
                        />
                    </FormControl>

                    <Button color="primary" type="submit" variant="contained">
                        Bestätigen
                    </Button>
                </form>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default MobileUser;
