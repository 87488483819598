import Cookies from "universal-cookie";

export const SERVER_BASE_URL =
    process.env.REACT_APP_SERVER_BASE_URL || "http://localhost:8080/attendance_monitor_server";

export class InvalidTokenError extends Error {
    constructor() {
        super("invalid token");
        Object.setPrototypeOf(this, InvalidTokenError.prototype);
    }
}

// utils

export async function fetchAndRenew(path: string, method: string, body?: string): Promise<Response> {
    // first make the request we want
    const token = new Cookies().get("jwt");
    const res = await fetch(`${SERVER_BASE_URL}${path}`, {
        method,
        headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
        },
        body,
    });
    if (res.status !== 200) {
        let error = await res.text();
        if (error === "Invalid token") throw new InvalidTokenError();
        throw new Error(error);
    }

    // then refresh our token
    const tokenResponse = await fetch(`${SERVER_BASE_URL}/refresh_token`, {
        method: "POST",
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    // if token invalid, return error
    if (tokenResponse.status !== 200) throw Error(await tokenResponse.text());
    const newToken = await tokenResponse.text();

    // otherwise set refreshed token and return original response
    new Cookies().set("jwt", newToken);

    return res;
}
