import { SERVER_BASE_URL, fetchAndRenew } from "./shared";
import Cookies from "universal-cookie";

export async function login(
    personalnummer: number,
    werkID: number,
    mandantenID: number,
    password: string
): Promise<void> {
    const body = { personalnummer, werkID, mandantenID, password };

    const response = await fetch(`${SERVER_BASE_URL}/login`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify(body),
    });
    if (response.status !== 200) throw new Error(await response.text());

    const token = await response.text();
    new Cookies().set("jwt", token);
}

export async function properLastCheckout(): Promise<boolean> {
    const response = await fetchAndRenew("/get_proper_checkout", "GET");
    const text = await response.text();

    if (text.toLowerCase() === "false") {
        return false;
    } else if (text.toLowerCase() === "true") {
        return true;
    } else {
        throw new Error("provided string was neither `true` nor `false`");
    }
}
