import React, { useContext, useEffect, useState, useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Select, MenuItem, Grid, Paper, Typography, useMediaQuery } from "@material-ui/core";
import Chart from "react-apexcharts";
import PieChart, { LabelProps } from "react-minimal-pie-chart";

import Status from "../components/Status";

import { GlobalContext } from "./Main";
import { InvalidTokenError } from "../api/shared";
import { isWeekend } from "../utils/date";
import { error, loading, Resource, success } from "../utils/resource";
import {
    AttendanceStatData,
    getAttendanceStats,
    getHolidayStats,
    getUserCount,
    HolidayStats,
    UserCount,
    AttendanceStatRange,
} from "../api/statistics";

const useStyles = makeStyles(theme => ({
    titleHeader: {
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
    },
    errorPaper: {
        marginBottom: theme.spacing(2),
    },
}));

interface Data {
    name: string;
    color: string;
    data: { x: Date; y: number }[];
}
interface BarChartProps {
    height: number;
    title: string;
    max: number;
    data: Data[];
}

const BarChart: React.FC<BarChartProps> = props => {
    const options = {
        chart: {
            type: "bar",
            stacked: true,
            toolbar: {
                enabled: true,
                tools: {
                    zoom: false,
                    pan: false,
                },
            },
            height: props.height,
            zoom: {
                type: "x",
                enabled: true,
            },
        },
        colors: props.data.map(data => data.color),
        markers: { size: 0 },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top",
                },
            },
        },
        yaxis: {
            labels: {
                formatter: (val: number) => val + "h",
            },
        },
        legend: {
            itemMargin: {
                vertical: 16,
            },
        },
        xaxis: {
            position: "bottom",
            type: "datetime",
            crosshairs: {
                width: 1,
            },
            labels: {
                datetimeFormatter: {
                    year: "yyyy",
                    month: "MMM",
                    day: "dd.MM",
                    hour: "",
                },
            },
        },
        tooltip: {
            y: {
                formatter: (val: number) => {
                    val = val * 60;
                    if (Math.abs(val) < 0.1) val = 0;

                    const minutes = val % 60;
                    const hours = Math.floor(val / 60);
                    let str = hours + "h ";
                    if (minutes !== 0) str += Math.round(minutes) + "min";
                    return str;
                },
            },

            shared: {
                enabled: true,
            },
            followCursor: true,
        },
        dataLabels: { enabled: false },
    };

    return <Chart options={options} series={props.data} type="bar" height={props.height} />;
};

const GaugeChart: React.FC<{ attendant: number; all: number }> = ({ attendant, all }) => {
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up("md"));

    const data = [all ? (attendant / all) * 100 : 0];

    const options = {
        chart: {
            type: "radialBar",
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#e7e7e7",
                    strokeWidth: "97%",
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: "#999",
                        opacity: 1,
                        blur: 2,
                    },
                },
                dataLabels: {
                    name: {
                        offsetY: -16,
                        fontSize: mdUp ? "16pt" : "12pt",
                        color: theme.palette.secondary.main,
                        formatter: () => `${attendant}/${all} Personen`,
                    },
                    value: {
                        show: false,
                    },
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "light",
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 53, 91],
            },
        },
        labels: ["Anwesende"],
    };

    return <Chart options={options} series={data} type="radialBar" />;
};

const targetMinutes = 6 * 80;

function attendance(value: AttendanceStatData): { x: Date; y: number } {
    // everything is overtime on weekends
    if (value.holiday || isWeekend(value.date)) {
        return { x: value.date, y: 0 };
    }

    // cap value.minutes at targetMinutes, e.g. 8h40min => 8h, 6h12min => 6h12min
    const minutes = Math.min(targetMinutes, value.minutes);
    return { x: value.date, y: minutes / 60 };
}

function overtime(value: AttendanceStatData): { x: Date; y: number } {
    // everything is overtime on weekends
    if (value.holiday || isWeekend(value.date)) {
        return { x: value.date, y: value.minutes / 60 };
    }

    // overshoot over targetMinutes, e.g. 8h40min => 1h20min, 6h12min => 0
    const diff = value.minutes - targetMinutes;
    let minutes = Math.max(0, diff);

    return { x: value.date, y: minutes / 60 };
}

function undertime(value: AttendanceStatData): { x: Date; y: number } {
    // no undertime on weekends
    if (value.holiday || isWeekend(value.date)) {
        return { x: value.date, y: 0 };
    }

    // below targetMinutes, e.g. 8h40min => 0, 6h12min => 1h48min
    const diff = targetMinutes - value.minutes;
    let minutes = Math.max(0, diff);

    return { x: value.date, y: minutes / 60 };
}

function chartDataFromAttendanceStats(list: AttendanceStatData[]): Data[] {
    let now = new Date();
    now.setHours(0, 0, 0);

    let data = [];
    let currentDate = new Date(list[0]?.date?.getTime());
    while (list.length !== 0 || currentDate.getTime() < now.getTime()) {
        if (list[0] !== undefined && list[0].date.getTime() <= currentDate.getTime()) {
            data.push(list.shift()!);
        } else {
            data.push({ date: new Date(currentDate.getTime()), minutes: 0, holiday: false });
        }

        currentDate.setDate(currentDate.getDate() + 1);
    }

    const hours = data.map(attendance);
    const overtimeData = data.map(overtime);
    const minusData = data.map(undertime);

    return [
        { name: "Sollstunden", color: "#269ffb", data: hours },
        { name: "Überstunden", color: "#26e7a3", data: overtimeData },
        { name: "Minusstunden", color: "#e72637", data: minusData },
    ];
}

const holidayDaysTakenColor = "#e74937";
const holidayDaysAvailableColor = "#49e793";
const holidayDaysTakenFutureColor = "#3472e7";

const Stats = () => {
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));
    const classes = useStyles();
    const { claims, showInvalidTokenModal } = useContext(GlobalContext);

    const [range, setRange] = useState<AttendanceStatRange>("month");

    const [attendanceData, setAttendanceData] = useState<Resource<AttendanceStatData[]>>(loading());
    useEffect(() => {
        getAttendanceStats(range)
            .then(data => setAttendanceData(success(data)))
            .catch(e => {
                if (e instanceof InvalidTokenError) {
                    showInvalidTokenModal();
                } else {
                    console.error(e);
                    setAttendanceData(error());
                }
            });
    }, [range, showInvalidTokenModal]);
    // useMemo == only rerun if attendanceData has changed
    const chartData = useMemo(() => {
        if (attendanceData.status === "success") {
            return chartDataFromAttendanceStats([...attendanceData.value]);
        } else {
            return [];
        }
    }, [attendanceData]);

    const [gaugeData, setGaugeData] = useState<Resource<UserCount | undefined>>(loading());
    const [holidayStats, setHolidayStats] = useState<Resource<HolidayStats>>(loading());

    useEffect(() => {
        const fetchUserCount: Promise<UserCount | undefined> =
            claims.roleID === 1 ? getUserCount() : Promise.resolve(undefined);
        const fetchHolidayStats: Promise<HolidayStats> = getHolidayStats();

        Promise.all([fetchUserCount, fetchHolidayStats])
            .then(([userCount, holidayStats]) => {
                setGaugeData(success(userCount!));
                setHolidayStats(success(holidayStats!));
            })
            .catch(e => {
                if (e instanceof InvalidTokenError) {
                    showInvalidTokenModal();
                } else {
                    console.error(e);
                    setHolidayStats(error());
                }
            });
    }, [claims.roleID, showInvalidTokenModal]);

    return (
        <>
            <Typography className={classes.titleHeader} component="h1" variant="h3" color="secondary">
                Statistiken
            </Typography>
            {(attendanceData.status === "error" || holidayStats.status === "error") && (
                <Paper className={classes.errorPaper}>
                    <Status type="error" />
                </Paper>
            )}
            <Grid container spacing={2}>
                <Grid item xs={12} hidden={attendanceData.status === "success" && attendanceData.value.length === 0}>
                    <Paper style={{ minHeight: 350, paddingBottom: 0 }} className={classes.paper}>
                        <Select
                            style={{ position: "absolute", zIndex: 10 }}
                            value={range}
                            onChange={e => setRange(e.target.value as AttendanceStatRange)}
                        >
                            <MenuItem value="week">Woche</MenuItem>
                            <MenuItem value="month">Monat</MenuItem>
                            <MenuItem value="year">Jahr</MenuItem>
                            <MenuItem value="all">Alle</MenuItem>
                        </Select>
                        {!smUp && <div style={{ marginBottom: "1rem" }} />}
                        {smUp && (
                            <Typography variant="h4" style={{ textAlign: "center" }}>
                                Anwesenheiten
                            </Typography>
                        )}
                        {attendanceData.status === "success" && (
                            <BarChart
                                title="Anwesenheit"
                                height={350}
                                data={chartData}
                                max={Math.max(...attendanceData.value.map(data => data.minutes))}
                            />
                        )}
                        {attendanceData.status === "loading" && <Status type="loading" />}
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={5}>
                    {holidayStats.status === "success" && (
                        <Paper className={classes.paper}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "start",
                                }}
                            >
                                <Typography variant="h4" style={{ textAlign: "center" }}>
                                    Urlaubstage für {new Date().getFullYear()}
                                </Typography>
                                <div style={{ width: "60%" }}>
                                    <PieChart
                                        data={[
                                            {
                                                color: holidayDaysAvailableColor,
                                                value: Math.max(
                                                    0,
                                                    holidayStats.value.days -
                                                        holidayStats.value.usedDays -
                                                        holidayStats.value.futureUsedDays
                                                ),
                                            },
                                            {
                                                color: holidayDaysTakenColor,
                                                value: holidayStats.value.usedDays,
                                            },
                                            {
                                                color: holidayDaysTakenFutureColor,
                                                value: holidayStats.value.futureUsedDays,
                                            },
                                        ]}
                                        radius={45}
                                        label={(lbl: LabelProps) => {
                                            const value = lbl.data[lbl.dataIndex].value;
                                            // if the value is zero, there is only one 100% filling slice, so we don't want to show two values there.
                                            // if the value is negative, it means that the user has had more holiday days since they are allowed to have,
                                            // which is applicable to admins who have unrestricted privileges around holiday days.
                                            return value <= 0 ? "" : value;
                                        }}
                                        labelStyle={{
                                            fill: "#fff",
                                            fontFamily: "sans-serif",
                                            fontSize: "0.5em",
                                        }}
                                    />
                                </div>

                                <div>
                                    {[
                                        { name: " Beanspruchte Urlaubstage", color: holidayDaysTakenColor },
                                        { name: " Geplante Urlaubstage", color: holidayDaysTakenFutureColor },
                                        { name: " Verbleibende Urlaubstage", color: holidayDaysAvailableColor },
                                    ].map(({ name, color }, i) => (
                                        <p key={i} style={{ margin: 0 }}>
                                            <span style={{ display: "inline", color, fontSize: "1.5em" }}>
                                                &#x25CF;
                                            </span>
                                            {name}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </Paper>
                    )}
                </Grid>
                <Grid item xs={12} sm={7}>
                    {gaugeData.status === "success" && gaugeData.value !== undefined && (
                        <Paper className={classes.paper}>
                            <Typography variant="h4" style={{ textAlign: "center" }}>
                                Anwesende Personen
                            </Typography>
                            <div style={{ maxHeight: 350 }}>
                                <GaugeChart
                                    attendant={gaugeData.value.attendantUserCount}
                                    all={gaugeData.value.allUserCount}
                                />
                            </div>
                        </Paper>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default Stats;
