import React, { useCallback, useEffect, useState, useContext } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, Button, Checkbox, Paper, Typography } from "@material-ui/core";
import { EditComponentProps } from "material-table";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import EventIcon from "@material-ui/icons/Event";

import MaterialTable from "../components/MaterialTable";
import ModalMsg from "../components/ModalMsg";
import Status from "../components/Status";

import { GlobalContext } from "./Main";
import { InvalidTokenError } from "../api/shared";
import { User, addUser, deleteUsers, getUsers, updateAccount } from "../api/users";
import { Resource, error, loading, success } from "../utils/resource";
import { dateToString, dateIsValid } from "../utils/date";
import { AbsenceList } from "./AbsencePlanner";

import MobileUser from "./AdminPanelMobileUser";

const useStyles = makeStyles(theme => ({
    titleHeader: {
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },
    usersList: {
        marginTop: theme.spacing(1),
        maxHeight: "25vh",
        overflow: "auto",
    },
    errorPaper: {
        marginBottom: theme.spacing(2),
    },
    userDetailPaper: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
    },
}));

const BirthDateEdit = (props: EditComponentProps<User>) => (
    <KeyboardDatePicker
        InputProps={{ style: { fontSize: 13 } }}
        variant="inline"
        value={props.rowData.birth}
        onChange={props.onChange}
        format="dd.MM.yyyy"
        disableFuture
    />
);
const StartDateEdit = (props: EditComponentProps<User>) => (
    <KeyboardDatePicker
        InputProps={{ style: { fontSize: 13 } }}
        variant="inline"
        value={props.rowData.startDate}
        onChange={props.onChange}
        format="dd.MM.yyyy"
    />
);

export const ERR_MSG_MISSING_FIELDS = "Nicht alle Felder wurden angegeben.";
export const ERR_MSG_DATE_INVALID = "Bitte geben sie ein korrektes Datum an.";
export const ERR_PASSWORD_INVALID = "Das angegebene Passwort ist leer.";

export type ModalState =
    | { type: "err"; msg: string }
    | { type: "putPassword"; user: User }
    | { type: "deleteSelf"; personalnummer: number; isSelf: boolean }
    | { type: "unadminSelf"; user: User; oldUser: User | undefined; password?: string };

const AdminPanel = () => {
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));

    const classes = useStyles();
    const { claims, reloadClaims, showInvalidTokenModal } = useContext(GlobalContext);
    const history = useHistory();

    const [users, setUsers] = useState<Resource<User[]>>(loading());
    const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

    const [modal, setModal] = useState<ModalState | undefined>(undefined);
    const closeModal = () => setModal(undefined);
    const [confirmSelfDeleteChecked, setConfirmSelfDeleteChecked] = useState(false);
    const [disableUserDelete, setDisableUserDelete] = useState(false);

    const userEditRef = React.createRef<HTMLDivElement>();

    const fetchUsers = useCallback(async () => {
        try {
            const users = await getUsers();
            setUsers(success(users));
        } catch (e) {
            if (e instanceof InvalidTokenError) {
                showInvalidTokenModal();
            } else {
                console.error(e);
                setUsers(error());
            }
        }
    }, [showInvalidTokenModal]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const deleteUser = (personalnummer: number, logoutAfterwards = false) =>
        deleteUsers([personalnummer])
            .then(() => {
                if (logoutAfterwards) {
                    new Cookies().remove("jwt");
                    history.push("/login");
                } else {
                    return fetchUsers();
                }
            })
            .catch(e => {
                if (e instanceof InvalidTokenError) {
                    showInvalidTokenModal();
                } else {
                    console.error(e);
                    setUsers(error());
                }
            });

    const updateUser = (newUser: User, oldUser: User | undefined, password?: string) => {
        const birth = new Date(newUser.birth);
        const startDate = new Date(newUser.startDate);
        if (!dateIsValid(birth) || !dateIsValid(startDate)) {
            setModal({ type: "err", msg: ERR_MSG_DATE_INVALID });
            return Promise.reject();
        }

        const options = {
            newFirstName: newUser.firstName !== oldUser?.firstName ? newUser.firstName : undefined,
            newLastName: newUser.lastName !== oldUser?.lastName ? newUser.lastName : undefined,
            newBirth: birth.getTime() !== oldUser?.birth?.getTime() ? newUser?.birth : undefined,
            newStartDate: startDate.getTime() !== oldUser?.startDate?.getTime() ? startDate : undefined,
            newRoleID: newUser.roleID !== oldUser?.roleID ? newUser.roleID : undefined,
            newPassword: password,
        };
        if (
            options.newFirstName === undefined &&
            options.newLastName === undefined &&
            options.newBirth === undefined &&
            options.newStartDate === undefined &&
            options.newRoleID === undefined &&
            options.newPassword === undefined
        ) {
            return Promise.resolve();
        }

        return updateAccount(newUser.personalnummer, options)
            .then(fetchUsers)
            .catch(e => {
                if (e instanceof InvalidTokenError) {
                    showInvalidTokenModal();
                } else {
                    console.error(e);
                    setUsers(error());
                }
            });
    };

    const addNewUser = (user: User, password: string) => {
        if (!dateIsValid(user.birth) || !dateIsValid(user.startDate)) {
            setModal({ type: "err", msg: ERR_MSG_DATE_INVALID });
            return Promise.reject();
        }

        const options = {
            firstName: user.firstName,
            lastName: user.lastName,
            roleID: user.roleID,
            birth: user.birth,
            startDate: user.startDate,
            password,
        };
        return addUser(options)
            .then(fetchUsers)
            .catch(e => {
                if (e instanceof InvalidTokenError) {
                    showInvalidTokenModal();
                } else {
                    console.error(e);
                    setUsers(error());
                }
            });
    };

    const renderModal = () => {
        switch (modal?.type) {
            case "err":
                return (
                    <ModalMsg
                        isOpen={true}
                        close={closeModal}
                        allowClose={true}
                        title="Fehler!"
                        description={modal.msg}
                    />
                );
            case "deleteSelf":
                return (
                    <ModalMsg
                        isOpen={true}
                        close={closeModal}
                        title="Achtung!"
                        description={
                            modal.isSelf
                                ? "Sie sind im Begriff sich selbst zu löschen"
                                : "Wollen Sie diesen Nutzer wirklich löschen?"
                        }
                        allowClose={true}
                    >
                        {modal.isSelf && (
                            <Checkbox
                                checked={confirmSelfDeleteChecked}
                                onChange={e => setConfirmSelfDeleteChecked(e.target.checked)}
                                value="primary"
                            />
                        )}
                        <Button
                            disabled={disableUserDelete || (modal.isSelf && !confirmSelfDeleteChecked)}
                            onClick={() => {
                                setDisableUserDelete(true);
                                deleteUser(modal.personalnummer, modal.isSelf).finally(() => {
                                    setModal(undefined);
                                    setDisableUserDelete(false);
                                });
                            }}
                            size="small"
                            variant="contained"
                        >
                            {modal.isSelf ? "Löschen und ausloggen" : "Löschen"}
                        </Button>
                    </ModalMsg>
                );
            case "unadminSelf":
                return (
                    <ModalMsg
                        isOpen={true}
                        close={closeModal}
                        title="Achtung!"
                        description="Sie sind im Begriff Ihren Admin-Zugang zu verlieren"
                        allowClose={true}
                    >
                        <Button
                            onClick={async () => {
                                await updateUser(modal.user, modal.oldUser, modal.password);
                                reloadClaims();
                                history.push("/");
                            }}
                            size="small"
                            variant="contained"
                        >
                            Bestätigen
                        </Button>
                    </ModalMsg>
                );
        }
    };

    function renderDesktopList() {
        return (
            <>
                <MaterialTable
                    title="Nutzer"
                    status={users.status}
                    actions={[
                        {
                            icon: () => <EventIcon />,
                            tooltip: "Nutzerabwesenheiten bearbeiten",
                            onClick: (_event, row) => {
                                const user = row as User;
                                if (user.personalnummer === selectedUser?.personalnummer) {
                                    setSelectedUser(undefined);
                                } else {
                                    setSelectedUser(user);
                                    userEditRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
                                }
                            },
                        },
                    ]}
                    columns={[
                        { title: "Personalnr.", field: "personalnummer", type: "numeric", editable: "never" },
                        { title: "Vorname", field: "firstName" },
                        { title: "Nachname", field: "lastName" },
                        {
                            title: "Geburtstag",
                            type: "date",
                            field: "birth",
                            editComponent: BirthDateEdit,
                            render: user => dateToString(user.birth),
                            initialEditValue: new Date(),
                        },
                        {
                            title: "Erster Arbeitstag",
                            type: "date",
                            field: "startDate",
                            editComponent: StartDateEdit,
                            render: user => dateToString(user.startDate),
                            initialEditValue: new Date(),
                        },
                        {
                            title: "Password",
                            field: "password",
                            editable: "always",
                            render: () => "******",
                        },
                        { title: "Rolle", field: "roleID", lookup: { 0: "Nutzer", 1: "Admin" } },
                    ]}
                    data={users.status === "success" ? users.value : []}
                    editable={{
                        onRowAdd: async user => {
                            const password: string | undefined = (user as any).password;
                            if (!user.firstName || !user.lastName || !user.roleID || !user.birth || !password) {
                                setModal({ type: "err", msg: ERR_MSG_MISSING_FIELDS });
                                throw new Error();
                            }

                            await addNewUser(user, password);
                        },
                        onRowUpdate: async (user, oldUser) => {
                            const password: string | undefined = (user as any).password;
                            if (password !== undefined && password === "") {
                                setModal({ type: "err", msg: ERR_PASSWORD_INVALID });
                                throw new Error();
                            }
                            if (user.personalnummer === claims.sub && Number(user.roleID) !== 1) {
                                setModal({ type: "unadminSelf", user, oldUser, password });
                            } else {
                                await updateUser(user, oldUser, password);
                            }
                        },
                        onRowDelete: async user => {
                            if (user.personalnummer === claims.sub) {
                                // is self
                                setModal({ type: "deleteSelf", personalnummer: user.personalnummer, isSelf: true });
                            } else {
                                await deleteUser(user.personalnummer);
                            }
                        },
                    }}
                />
                {selectedUser && (
                    <Paper className={classes.userDetailPaper}>
                        <Typography>
                            {selectedUser.firstName} {selectedUser.lastName}:
                        </Typography>
                        <AbsenceList personalnummer={selectedUser.personalnummer} />
                    </Paper>
                )}
                <div ref={userEditRef} />
            </>
        );
    }

    function renderMobileList(users: User[]) {
        return (
            <div style={{ width: "100%" }}>
                <MobileUser setUsers={setUsers} setModal={setModal} fetchUsers={fetchUsers} key={"add"+users.length} />
                {users.map((user, idx) => (
                    <MobileUser
                        setUsers={setUsers}
                        setModal={setModal}
                        fetchUsers={fetchUsers}
                        key={user.personalnummer}
                        user={user}
                    />
                ))}
            </div>
        );
    }

    return (
        <>
            {renderModal()}
            <Typography className={classes.titleHeader} component="h1" variant="h3" color="secondary">
                Admin panel
            </Typography>
            {users.status === "error" && (
                <Paper className={classes.errorPaper}>
                    <Status type="error" />
                </Paper>
            )}
            {users.status === "loading" && (
                <Paper className={classes.errorPaper}>
                    <Status type="loading" />
                </Paper>
            )}

            {smUp ? renderDesktopList() : users.status === "success" && renderMobileList(users.value)}
        </>
    );
};

export default AdminPanel;
