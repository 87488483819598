import { fetchAndRenew, SERVER_BASE_URL, InvalidTokenError } from "./shared";
import { toLocalISOString } from "../utils/date";
import { parseISO } from "date-fns";
import Cookies from "universal-cookie";

export interface User {
    personalnummer: number;
    firstName: string;
    lastName: string;
    birth: Date;
    startDate: Date;
    roleID: number;
}

export async function getUsers(): Promise<User[]> {
    const users = await fetchAndRenew("/get_users", "GET").then(res => res.json());
    for (let user of users) {
        user.birth = parseISO(user.birth);
        user.startDate = parseISO(user.startDate);
    }

    return users;
}

export async function deleteUsers(userIDs: number[]): Promise<void> {
    const token = new Cookies().get("jwt");
    const body = { personalnummern: userIDs };
    const response = await fetch(`${SERVER_BASE_URL}/delete_users`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });
    if (response.status !== 200) {
        let error = await response.text();
        if (error === "Invalid token") throw new InvalidTokenError();
        throw new Error(error);
    }
}

export interface AddUserOptions {
    firstName: string;
    lastName: string;
    roleID: number;
    birth: Date;
    startDate: Date;
    password: string;
}
export async function addUser(options: AddUserOptions): Promise<void> {
    const dto = {
        ...options,
        birth: toLocalISOString(options.birth).split("T")[0],
        startDate: toLocalISOString(options.birth).split("T")[0],
    };
    await fetchAndRenew("/add_user", "PUT", JSON.stringify(dto));
}

export interface UpdateAccountOptions {
    newFirstName?: string;
    newLastName?: string;
    newBirth?: Date;
    newStartDate?: Date;
    oldPassword?: string;
    newPassword?: string;
    newRoleID?: number;
}

export async function updateAccount(personalnummer: number, options: UpdateAccountOptions): Promise<void> {
    const newBirth = options.newBirth ? toLocalISOString(options.newBirth).split("T")[0] : undefined;
    const newStartDate = options.newStartDate ? toLocalISOString(options.newStartDate).split("T")[0] : undefined;
    const dto = {
        personalnummer,
        newBirth,
        newStartDate,
        newFirstName: options.newFirstName,
        newLastName: options.newLastName,
        newPassword: options.newPassword,
        oldPassword: options.oldPassword,
        newRoleID: options.newRoleID,
    };
    await fetchAndRenew("/change_user_data", "POST", JSON.stringify(dto));
}
