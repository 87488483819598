import React, { useEffect, useState, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { EditComponentProps } from "material-table";

import MaterialTable from "../components/MaterialTable";
import ModalMsg from "../components/ModalMsg";
import Status from "../components/Status";

import { InvalidTokenError } from "../api/shared";
import { Resource, error, loading, success } from "../utils/resource";
import { Attendance, getPersonalAttendances, addMissingCheckout } from "../api/attendances";
import { dateIsValid, dateToString } from "../utils/date";
import { GlobalContext } from "./Main";

const useStyles = makeStyles(theme => ({
    titleHeader: {
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
    },
    errorPaper: {
        marginBottom: theme.spacing(2),
    },
}));

const DateEdit = (props: EditComponentProps<Attendance>) => (
    <KeyboardDateTimePicker
        InputProps={{ style: { fontSize: 13 } }}
        variant="inline"
        ampm={false}
        value={props.rowData.timeOut ?? props.rowData.timeIn}
        onChange={props.onChange}
        format="dd.MM.yyyy HH:mm"
        disableFuture
    />
);

interface YourAttendancesProps {
    showInvalidTokenModal(): void;
}

const YourAttendances = () => {
    const { showInvalidTokenModal } = useContext(GlobalContext);

    const ERR_MSG_TIME_OUT_NOT_SET = "Die Auscheck-Zeit der Anwesenheit muss angegeben werden.";
    const ERR_MSG_OUT_BEFORE_IN = "Die Auscheck-Zeit liegt vor der Eincheck-Zeit.";

    const classes = useStyles();

    const [errModalMsg, setErrModalMsg] = useState<string | undefined>(undefined);

    const [attendances, setAttendances] = useState<Resource<Attendance[]>>(loading());

    const fetchAttendances = useCallback(async () => {
        try {
            const attendances = await getPersonalAttendances();
            setAttendances(success(attendances));
        } catch (e) {
            if (e instanceof InvalidTokenError) {
                showInvalidTokenModal();
            } else {
                setAttendances(error());
                console.error(e);
            }
        }
    }, [showInvalidTokenModal]);

    useEffect(() => {
        fetchAttendances();
    }, [fetchAttendances]);

    return (
        <>
            <ModalMsg
                isOpen={errModalMsg !== undefined}
                close={() => setErrModalMsg(undefined)}
                allowClose={true}
                title="Fehler!"
                description={errModalMsg!}
            />
            <Typography className={classes.titleHeader} component="h1" variant="h3" color="secondary">
                Ihre Anwesenheiten
            </Typography>
            {attendances.status === "error" && (
                <Paper className={classes.errorPaper}>
                    <Status type="error" />
                </Paper>
            )}
            <MaterialTable
                title="Anwesenheit"
                status={attendances.status}
                options={{
                    search: false,
                }}
                columns={[
                    {
                        title: "Von",
                        field: "timeIn",
                        type: "datetime",
                        defaultSort: "desc",
                        editable: "never",
                        render: date => dateToString(date.timeIn),
                    },
                    {
                        title: "Bis",
                        field: "timeOut",
                        type: "datetime",
                        editComponent: DateEdit,
                        render: date =>
                            date.timeOut !== undefined ? dateToString(date.timeOut) : <b>Nicht abgemeldet</b>,
                    },
                ]}
                data={attendances.status === "success" ? attendances.value : []}
                editable={{
                    isEditable: row => row.timeOut === undefined,
                    onRowUpdate: async (row: Attendance, old?: Attendance) => {
                        const timeIn = new Date(row.timeIn);
                        const timeOut = new Date(row.timeOut === undefined ? row.timeIn : row.timeOut);
                        if (!dateIsValid(timeOut)) {
                            setErrModalMsg(ERR_MSG_TIME_OUT_NOT_SET);
                            throw new Error();
                        }

                        if (timeOut < timeIn) {
                            setErrModalMsg(ERR_MSG_OUT_BEFORE_IN);
                            throw new Error();
                        }

                        addMissingCheckout(timeIn, timeOut)
                            .then(fetchAttendances)
                            .catch(e => {
                                if (e instanceof InvalidTokenError) {
                                    showInvalidTokenModal();
                                } else {
                                    setAttendances(error());
                                    console.error(e);
                                }
                            });
                    },
                }}
            />
        </>
    );
};

export default YourAttendances;
