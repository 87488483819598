import React, { ChangeEvent, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import ModalMsg from "../components/ModalMsg";

import { GlobalContext } from "./Main";
import { InvalidTokenError } from "../api/shared";
import { timestampToDate, timestampToString } from "../utils/date";
import { updateAccount } from "../api/users";

const useStyles = makeStyles(theme => ({
    titleHeader: {
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },
    paper: {
        textAlign: "center",
        padding: theme.spacing(4, 3),
    },
    dividerItem: {
        margin: theme.spacing(0, 2),
    },
}));

interface Form {
    firstName: {
        isEditable: boolean;
        value?: string;
    };
    lastName: {
        isEditable: boolean;
        value?: string;
    };
    birth: {
        isEditable: boolean;
    };
    password: {
        isEditable: boolean;
        value?: string;
    };
    newPassword: {
        isEditable: boolean;
        value?: string;
    };
    confirmNewPassword: {
        isEditable: boolean;
        value?: string;
    };
}
function formDefaults() {
    return {
        firstName: { isEditable: false, value: undefined },
        lastName: { isEditable: false, value: undefined },
        birth: { isEditable: false },
        password: { isEditable: false, value: undefined },
        newPassword: { isEditable: false, value: undefined },
        confirmNewPassword: { isEditable: false, value: undefined },
    };
}

function translateError(e: string): string {
    switch (e) {
        case "No changed requested":
            return "Keine Änderungen angefordert";
        case "Password invalid":
            return "Das angegebene Passwort ist inkorrekt";
        default:
            console.error(e);
            return "Ein Fehler bei der Abfrage des Servers ist aufgetreten";
    }
}

const Settings = () => {
    const classes = useStyles();
    const { claims, reloadClaims, showInvalidTokenModal } = useContext(GlobalContext);

    const [form, setForm] = useState<Form>(formDefaults());
    const [selectedDate, handleDateChange] = useState<Date | null>(claims ? timestampToDate(claims.birth) : null);

    // toggle password icon
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    // toggle newPassword icon
    const [showNewPassword, setShowNewPassword] = useState(false);
    const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const [modalShown, setModalShown] = useState(false);

    const updateField = (e: ChangeEvent<HTMLInputElement>) => {
        setError(undefined);
        setForm({
            ...form,
            [e.target.name]: { isEditable: true, value: e.target.value },
        });
    };

    // field can be "firstName", "lastName" etc.
    const setFieldEditable = (field: keyof Form) => {
        setError(undefined);
        setForm({
            ...form,
            [field]: { isEditable: true, value: form[field] },
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { firstName, lastName, password, newPassword, confirmNewPassword } = form;
        const birth = selectedDate !== null && form.birth.isEditable ? selectedDate : undefined;

        if (!showNewPassword && confirmNewPassword.value !== newPassword.value) {
            setError("Passwort und Bestätigung stimmen nicht überein");
            return;
        }

        setIsLoading(true);
        try {
            const options = {
                newFirstName: firstName.value,
                newLastName: lastName.value,
                newBirth: birth,
                oldPassword: password.value,
                newPassword: newPassword.value,
            };
            await updateAccount(claims!.sub, options);
            setForm(formDefaults());
            setModalShown(true);
            reloadClaims();
        } catch (e) {
            if (e instanceof InvalidTokenError) {
                showInvalidTokenModal();
            } else {
                setError(translateError(e.message));
            }
        }
        setIsLoading(false);
    };

    return (
        <>
            <ModalMsg
                isOpen={modalShown}
                close={() => setModalShown(false)}
                allowClose={true}
                title="Erfolg"
                description="Accountdaten wurden geändert"
            >
                <Button size="small" variant="contained" onClick={() => setModalShown(false)}>
                    Okay
                </Button>
            </ModalMsg>
            <Typography className={classes.titleHeader} component="h1" variant="h3" color="secondary">
                Einstellungen
            </Typography>
            <Paper className={classes.paper}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="firstName">Vorname</InputLabel>
                                <OutlinedInput
                                    name="firstName"
                                    id="firstName"
                                    type="text"
                                    defaultValue={claims?.firstName}
                                    onChange={updateField}
                                    disabled={!form.firstName.isEditable}
                                    labelWidth={65}
                                    endAdornment={
                                        form.firstName.isEditable ? null : (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle possibility to edit first name"
                                                    onClick={() => setFieldEditable("firstName")}
                                                    onMouseDown={e => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {<EditIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="lastName">Nachname</InputLabel>
                                <OutlinedInput
                                    name="lastName"
                                    id="lastName"
                                    type="text"
                                    defaultValue={claims?.lastName}
                                    onChange={updateField}
                                    disabled={!form.lastName.isEditable}
                                    labelWidth={80}
                                    endAdornment={
                                        form.lastName.isEditable ? null : (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle possibility to edit last name"
                                                    onClick={() => setFieldEditable("lastName")}
                                                    onMouseDown={e => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {<EditIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                />
                            </FormControl>
                        </Grid>
                        {form.birth.isEditable ? (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="outlined">
                                    <KeyboardDatePicker
                                        autoOk
                                        disableFuture
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="Geburtsdatum"
                                        format="dd.MM.yyyy"
                                        value={selectedDate}
                                        InputAdornmentProps={{ position: "end" }}
                                        KeyboardButtonProps={{ edge: "end" }}
                                        onChange={handleDateChange}
                                    />
                                </FormControl>
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="birthday">Geburtstag</InputLabel>
                                    <OutlinedInput
                                        name="birthday"
                                        id="birthday"
                                        type="text"
                                        defaultValue={claims ? timestampToString(claims?.birth) : undefined}
                                        disabled={!form.birth.isEditable}
                                        labelWidth={80}
                                        endAdornment={
                                            form.birth.isEditable ? null : (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle possibility to edit birth"
                                                        onClick={() => setFieldEditable("birth")}
                                                        onMouseDown={e => e.preventDefault()}
                                                        edge="end"
                                                    >
                                                        {<EditIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        )}

                        <Grid className={classes.dividerItem} item xs={12}>
                            <Box mx={-4} my={2}>
                                <Divider variant="middle" />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="password">Aktuelles Passwort</InputLabel>
                                <OutlinedInput
                                    name="password"
                                    id="password"
                                    required
                                    type={showPassword ? "text" : "password"}
                                    onChange={updateField}
                                    disabled={!form.password.isEditable}
                                    labelWidth={140}
                                    endAdornment={
                                        form.password.isEditable ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={toggleShowPassword}
                                                    onMouseDown={e => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ) : (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle possibility to edit password"
                                                    onClick={() => setFieldEditable("password")}
                                                    onMouseDown={e => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {<EditIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                />
                            </FormControl>
                        </Grid>
                        {form.password.isEditable ? (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="password">Neues Passwort</InputLabel>
                                    <OutlinedInput
                                        name="newPassword"
                                        id="newPassword"
                                        type={showNewPassword ? "text" : "password"}
                                        onChange={updateField}
                                        labelWidth={120}
                                        required
                                        endAdornment={
                                            form.password.isEditable ? (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={toggleShowNewPassword}
                                                        onMouseDown={e => e.preventDefault()}
                                                        edge="end"
                                                    >
                                                        {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : null
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        ) : null}
                        {form.password.isEditable && !showNewPassword ? (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="confirmNewPassword">Passwort bestätigen</InputLabel>
                                    <OutlinedInput
                                        name="confirmNewPassword"
                                        id="confirmNewPassword"
                                        type={"password"}
                                        onChange={updateField}
                                        required
                                        labelWidth={147}
                                    />
                                </FormControl>
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            {error ? (
                                <FormHelperText style={{ marginBottom: "1rem", marginTop: "-0.25rem" }} error={true}>
                                    {error}
                                </FormHelperText>
                            ) : null}
                            <Button type="submit" fullWidth variant="contained" color={"primary"}>
                                {isLoading ? "Lädt..." : "Ändern"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    );
};

export default Settings;
