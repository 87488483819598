export type Resource<T> = { status: "error"; msg?: string } | { status: "success"; value: T } | { status: "loading" };

export function loading<T>(): Resource<T> {
    return { status: "loading" };
}

export function success<T>(value: T): Resource<T> {
    return { status: "success", value };
}

export function error<T>(msg?: string): Resource<T> {
    return { status: "error", msg };
}
