import { fetchAndRenew } from "./shared";
import { parseISO } from "date-fns";
import { toLocalISOString } from "../utils/date";

const MAX_HOLIDAY_WARN = "Warning: Reached the maximum of holidays ";
function extract_overdrawn(text: string): number | undefined {
    if (text.startsWith(MAX_HOLIDAY_WARN)) {
        const amount = Number(text.substr(MAX_HOLIDAY_WARN.length));
        return amount;
    }
}

export interface PlannedAbsence {
    reason: string;
    start: Date;
    end: Date;
}

export async function getPlannedAbsences(personalnummer?: number): Promise<PlannedAbsence[]> {
    let path = "/get_user_absence";
    if (personalnummer) path += "?personalnummer=" + personalnummer;
    const absences = await fetchAndRenew(path, "GET").then(res => res.json());
    return absences.map((val: any) => ({
        start: parseISO(val.start),
        end: parseISO(val.end),
        reason: val.grund,
    }));
}

export interface UpdateAbsenceDTO {
    oldStart: Date;
    oldEnd: Date;
    newStart?: Date;
    newEnd?: Date;
    newReason?: string;
    personalnummer?: number;
}
export async function updatePlannedAbsence(
    change_options: UpdateAbsenceDTO,
    confirmOverdraw?: boolean
): Promise<number | undefined> {
    const body = {
        oldStart: toLocalISOString(change_options.oldStart),
        oldEnd: toLocalISOString(change_options.oldEnd),
        newStart: change_options.newStart ? toLocalISOString(change_options.newStart) : undefined,
        newEnd: change_options.newEnd ? toLocalISOString(change_options.newEnd) : undefined,
        newReason: change_options.newReason,
        personalnummer: change_options.personalnummer,
        confirmOverdraw,
    };
    const response = await fetchAndRenew("/change_user_absence", "POST", JSON.stringify(body));
    const text = await response.text();

    return extract_overdrawn(text);
}

export async function addPlannedAbsence(
    start: Date,
    end: Date,
    reason: string,
    personalnummer?: number,
    confirmOverdraw?: boolean
): Promise<number | undefined> {
    const body = {
        start: toLocalISOString(start),
        end: toLocalISOString(end),
        reason,
        personalnummer,
        confirmOverdraw,
    };
    const response = await fetchAndRenew("/add_user_absence", "PUT", JSON.stringify(body));
    const text = await response.text();

    return extract_overdrawn(text);
}

export async function deletePlannedAbsence(start: Date, end: Date, personalnummer?: number): Promise<void> {
    const body = {
        start: toLocalISOString(start),
        end: toLocalISOString(end),
        personalnummer,
    };
    await fetchAndRenew("/delete_user_absence", "POST", JSON.stringify(body));
}
