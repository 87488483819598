import React, { ChangeEvent, useState } from "react";
import {
    Button,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Theme,
    Typography,
    createStyles,
    makeStyles,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory } from "react-router-dom";

import { login } from "../api/login";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: "100vh",
        },
        paper: {
            textAlign: "center",
            padding: theme.spacing(4, 3),
            margin: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
                margin: theme.spacing(6),
            },
        },
        title: {
            fontWeight: 800,
            marginBottom: theme.spacing(2),
            color: "#454340",
        },
        submitBtn: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    })
);

interface Form {
    personalnummer: string;
    werkID: string;
    mandantenID: string;
    password: string;
}

export enum LoginError {
    InvalidCombination = "Combination of personalnummer, werkID and mandantenID not found",
    InvalidPassword = "Password invalid",
    DBError = "Database error",
}
function errorText(err: LoginError): string {
    switch (err) {
        case LoginError.InvalidCombination:
            return "Es wurde keine gültige Kombination aus Mandant, Werk und Personalnummer gefunden";
        case LoginError.InvalidPassword:
            return "Das angegebene Passwort ist ungültig";
        case LoginError.DBError:
            return "Fehler beim Abfragen der Datenbank";
        default:
            return "Fehler: " + err;
    }
}

const LoginComponent = () => {
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const isMobile = useMediaQuery(theme.breakpoints.up("sm"));

    // form / error / input change handler
    const [form, setForm] = useState<Form>({
        personalnummer: "",
        werkID: "",
        mandantenID: "",
        password: "",
    });
    const [error, setError] = useState<LoginError | undefined>(undefined);
    const updateField = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    // toggle password icon
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    // submit handling
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(undefined);

        if (isLoading) return;

        const { personalnummer, werkID, mandantenID, password } = form;

        setIsLoading(true);

        try {
            await login(Number(personalnummer), Number(werkID), Number(mandantenID), password!);
            const redirect = new URLSearchParams(history.location.search).get("redirect");
            history.push(redirect || "/");
        } catch (e) {
            setIsLoading(false);
            setError(e.message as LoginError);
        }
    };

    return (
        <Grid
            className={classes.container}
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Container maxWidth="md">
                <Paper className={classes.paper}>
                    <Typography
                        className={classes.title}
                        component="h1"
                        variant={isMobile ? "h3" : "h4"}
                        color="inherit"
                    >
                        Attendance Monitor
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1} justify="space-between">
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    error={error === LoginError.InvalidCombination}
                                    fullWidth
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="mandant">Mandant</InputLabel>
                                    <OutlinedInput
                                        name="mandantenID"
                                        id="mandant"
                                        type="number"
                                        required
                                        autoFocus
                                        onChange={updateField}
                                        labelWidth={60}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    error={error === LoginError.InvalidCombination}
                                    fullWidth
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="werk">Werk</InputLabel>
                                    <OutlinedInput
                                        name="werkID"
                                        id="werk"
                                        type="number"
                                        required
                                        onChange={updateField}
                                        labelWidth={40}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    error={error === LoginError.InvalidCombination}
                                    fullWidth
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="personalnummer">Personalnummer</InputLabel>
                                    <OutlinedInput
                                        name="personalnummer"
                                        id="personalnummer"
                                        type="number"
                                        required
                                        onChange={updateField}
                                        labelWidth={125}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl error={error === LoginError.InvalidPassword} fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Passwort</InputLabel>
                                    <OutlinedInput
                                        name="password"
                                        id="outlined-adornment-password"
                                        type={showPassword ? "text" : "password"}
                                        required
                                        onChange={updateField}
                                        labelWidth={70}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={toggleShowPassword}
                                                    onMouseDown={e => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {error !== undefined ? (
                                        <FormHelperText error={true}>{errorText(error)}</FormHelperText>
                                    ) : (
                                        undefined
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    className={classes.submitBtn}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color={"primary"}
                                >
                                    {isLoading ? "Lädt..." : "Einloggen"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </Grid>
    );
};

export default LoginComponent;
