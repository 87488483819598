import React, { useEffect, useState, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    Button,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    AppBar,
} from "@material-ui/core";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AdminIcon from "@material-ui/icons/Security";
import ChartIcon from "@material-ui/icons/BarChart";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EventIcon from "@material-ui/icons/Event";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";

import ModalMsg from "../components/ModalMsg";

import AbsencePlanner from "./AbsencePlanner";
import AdminPanel from "./AdminPanel";
import Attendances from "./Attendances";
import Settings from "./Settings";
import Stats from "./Stats";
import YourAttendances from "./YourAttendances";

import { Claims } from "../api/jwt";
import { InvalidTokenError } from "../api/shared";
import { properLastCheckout } from "../api/login";

export const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },
    drawer: {
        [theme.breakpoints.up("md")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        ...theme.mixins.toolbar,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    usernameBox: {
        padding: theme.spacing(1),
        margin: "auto",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    navlinkItem: {
        textDecoration: "none",
        color: "inherit",
    },

    contentDiv: {
        // on xs, take what you need and dont be too wide
        maxWidth: `calc(100vw - ${theme.spacing(4)}px)`,

        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },

        // on sm, have at least 90% width (maxWidth still applies)
        [theme.breakpoints.up("sm")]: {
            width: "90vw",
        },

        // on sm only take up 66% (and calc maxWidth with the drawer)
        [theme.breakpoints.up("md")]: {
            width: "66vw",
            maxWidth: `calc(100vw - ${theme.spacing(4)}px - ${drawerWidth}px)`,
        },
    },
}));

export interface GlobalState {
    claims: Claims;
    reloadClaims(): void;
    showInvalidTokenModal(): void;
}
export const GlobalContext = React.createContext<GlobalState>({} as GlobalState);

const Main = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const [mobileOpen, setMobileOpen] = useState(false);
    const closeDrawer = () => setMobileOpen(false);
    const toggleDrawer = () => {
        setMobileOpen(!mobileOpen);
    };

    const [invalidTokenModal, setInvalidTokenModal] = useState(false);
    const [improperLastCheckout, setImproperLastCheckout] = useState(false);

    const [claims, setClaims] = useState<Claims | undefined>(undefined);
    const reloadClaims = useCallback(() => {
        const cookies = new Cookies();

        const token = cookies.get("jwt");
        if (!token) {
            history.push("/login");
            return;
        }

        try {
            setClaims(jwtDecode(token));
        } catch (e) {
            setInvalidTokenModal(true);
        }
    }, [history]);
    const isAdmin = () => (claims?.roleID || 0) === 1;

    useEffect(() => {
        reloadClaims();

        properLastCheckout()
            .then(properLastCheckout => {
                setImproperLastCheckout(!properLastCheckout);
            })
            .catch(e => {
                if (e instanceof InvalidTokenError) {
                    setInvalidTokenModal(true);
                } else {
                    console.error(e);
                }
            });
    }, [reloadClaims]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleLogout = (redirect?: string) => {
        new Cookies().remove("jwt");
        let path = "/login";
        if (redirect && redirect !== "/") path += "?redirect=" + redirect;
        history.push(path);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar}>
                <Typography variant="h6" noWrap className={classes.usernameBox}>
                    {`${claims?.firstName} ${claims?.lastName}`}
                </Typography>
            </div>
            <Divider />
            <List>
                <Link className={classes.navlinkItem} to="/">
                    <ListItem button onClick={closeDrawer}>
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Übersicht" />
                    </ListItem>
                </Link>
                <Link className={classes.navlinkItem} to="/plan_absence">
                    <ListItem button onClick={closeDrawer}>
                        <ListItemIcon>
                            <EventIcon />
                        </ListItemIcon>
                        <ListItemText primary="Abwesenheit planen" />
                    </ListItem>
                </Link>
                <Link className={classes.navlinkItem} to="/your_attendances">
                    <ListItem button onClick={closeDrawer}>
                        <ListItemIcon>
                            <DateRangeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Ihre Anwesenheiten" />
                    </ListItem>
                </Link>
                <Link className={classes.navlinkItem} to="/stats">
                    <ListItem button onClick={closeDrawer}>
                        <ListItemIcon>
                            <ChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Statistiken" />
                    </ListItem>
                </Link>
            </List>
            <Divider />
            <List>
                <Link className={classes.navlinkItem} to="/settings">
                    <ListItem button onClick={closeDrawer}>
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Einstellungen" />
                    </ListItem>
                </Link>
                {isAdmin() && (
                    <Link className={classes.navlinkItem} to={"/admin_panel"}>
                        <ListItem button onClick={closeDrawer}>
                            <ListItemIcon>
                                <AdminIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Admin panel"} />
                        </ListItem>
                    </Link>
                )}
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <ModalMsg
                isOpen={invalidTokenModal}
                close={() => setInvalidTokenModal(false)}
                allowClose={false}
                title="Die Sitzung ist abgelaufen"
                description="Klicken Sie hier, um zum Login weitergeleitet zu werden:"
            >
                <Button onClick={() => handleLogout(history.location.pathname)} size="small" variant="contained">
                    Login
                </Button>
            </ModalMsg>
            <ModalMsg
                isOpen={improperLastCheckout}
                close={() => setImproperLastCheckout(false)}
                allowClose={false}
                title="Sie haben sich letzes Mal nicht ausgecheckt!"
                description="Klicken Sie hier, um dies nachzutragen."
            >
                <Button
                    onClick={() => {
                        setImproperLastCheckout(false);
                        if (history.location.pathname !== "/attendances") history.push("/your_attendances");
                    }}
                    size="small"
                    variant="contained"
                >
                    Ihre Anwesenheiten
                </Button>
            </ModalMsg>

            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={toggleDrawer}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Attendance Monitor
                    </Typography>
                    <IconButton color="inherit" aria-label="logout" onClick={() => handleLogout()}>
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={mobileOpen}
                        onClose={closeDrawer}
                        className={classes.drawerPaper}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer className={classes.drawerPaper} variant="permanent" open>
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            {claims !== undefined && (
                <GlobalContext.Provider
                    value={{ claims, reloadClaims, showInvalidTokenModal: () => setInvalidTokenModal(true) }}
                >
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Switch>
                            <Route exact path="/">
                                <div className={classes.contentDiv}>
                                    <Attendances />
                                </div>
                            </Route>
                            <Route path="/plan_absence">
                                <div className={classes.contentDiv}>
                                    <AbsencePlanner />
                                </div>
                            </Route>
                            <Route path="/your_attendances">
                                <div className={classes.contentDiv}>
                                    <YourAttendances />
                                </div>
                            </Route>
                            <Route path="/stats">
                                <div className={classes.contentDiv}>
                                    <Stats />
                                </div>
                            </Route>
                            <Route path="/settings">
                                <div className={classes.contentDiv}>
                                    <Settings />
                                </div>
                            </Route>
                            {isAdmin() && (
                                <Route path="/admin_panel">
                                    <div className={classes.contentDiv}>
                                        <AdminPanel />
                                    </div>
                                </Route>
                            )}
                            <Route path="*">
                                <Typography variant="h3">Error 404</Typography>
                                <Typography variant="h4">
                                    Die Seite "{history.location.pathname.substr(1)}" wurde nicht gefunden
                                </Typography>
                            </Route>
                        </Switch>
                    </main>
                </GlobalContext.Provider>
            )}
        </div>
    );
};

export default Main;
