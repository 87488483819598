export function pad(number: number): string {
    if (number < 10) {
        return "0" + number;
    }
    return number.toString();
}

export function dateIsValid(date: Date): boolean {
    // if the date isn't valid, getTime will return NaN, which isn't equal to itself
    // eslint-disable-next-line no-self-compare
    return date.getTime() === date.getTime();
}

export function timestampToString(timestamp: number): string {
    const date = timestampToDate(timestamp);
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
}

export function timestampToDate(timestamp: number): Date {
    return new Date(timestamp * 1000);
}

export function dateToString(date: Date, includeYear = true): string {
    if (date.getHours() === 0 && date.getMinutes() === 0) {
        return date.toLocaleString(["de"], {
            year: includeYear ? "numeric" : undefined,
            month: "2-digit",
            day: "2-digit",
        });
    } else {
        const str = date.toLocaleString(["de"], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        });
        return str + " Uhr";
    }
}

export function isWeekend(date: Date) {
    const weekday = date.getDay();
    return weekday === 0 || weekday === 6;
}

export function sameDay(a: Date, b: Date): boolean {
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
}

export function isToday(date: Date) {
    const today = new Date();
    return sameDay(date, today);
}

export function isYesterday(date: Date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return sameDay(date, yesterday);
}

export function friendlyDate(date: Date): string {
    const time = date.toLocaleTimeString(["de"], { hour: "2-digit", minute: "2-digit" });

    const today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1); // getDate(0) sets to last month

    let datestr;
    if (isToday(date)) {
        datestr = "Heute";
    } else if (isYesterday(date)) {
        datestr = "Gestern";
    } else {
        datestr = date.toLocaleDateString(["de"], { year: "numeric", month: "2-digit", day: "2-digit" });
    }

    return `${datestr}, ${time} Uhr`;
}

export function toLocalISOString(date: Date) {
    return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        "." +
        (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
        "Z"
    );
}
