import { makeStyles, CircularProgress, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import React from "react";

const useStyles = makeStyles(theme => ({
    div: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
    },
}));

export type StatusProps = { type: "loading" } | { type: "error"; msg?: string } | { type: "info"; msg: string };

const Status: React.FC<StatusProps> = props => {
    const classes = useStyles();

    function msg(): string {
        switch (props.type) {
            case "loading":
                return "Lädt...";
            case "error":
                return props.msg || "Fehler: Anfrage an den Server konnte nicht erfolgreich durchgeführt werden";
            case "info": {
                return props.msg;
            }
        }
    }

    return (
        <div className={classes.div}>
            {props.type === "error" && <ErrorIcon fontSize="large" color="error" />}
            {props.type === "loading" && <CircularProgress />}
            <Typography style={{ paddingLeft: "0.5rem" }}>
                <b>{msg()}</b>
            </Typography>
        </div>
    );
};

export default Status;
