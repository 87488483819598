import { useEffect, useRef } from "react";

export function useInterval(callback: () => void, delayMs: number | null) {
    const savedCallback = useRef<() => void>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current!();
        }
        if (delayMs !== null) {
            let id = setInterval(tick, delayMs);
            return () => clearInterval(id);
        }
    }, [delayMs]);
}
