import { fetchAndRenew } from "./shared";
import { toLocalISOString } from "../utils/date";

export enum AttendanceStatus {
    Attendant = "ATTENDANT",
    Absent = "ABSENT",
}

export interface UserInfo {
    attendance: AttendanceStatus;
    reason: string;
    personalnummer: number;
    firstName: string;
    lastName: string;
}

export async function fetchUserAttendanceList(): Promise<UserInfo[]> {
    return await fetchAndRenew("/attendances", "GET").then(res => res.json());
}

export interface Attendance {
    timeIn: Date;
    timeOut?: Date;
}

export async function getPersonalAttendances(): Promise<Attendance[]> {
    const response = await fetchAndRenew("/get_user_attendances", "GET").then(res => res.json());

    return response.map((val: any) => {
        const timeIn = new Date(val.timeIn);
        const timeOut = val.timeOut ? new Date(val.timeOut) : undefined;
        return { timeIn, timeOut };
    });
}

export async function addMissingCheckout(timeIn: Date, timeOut: Date): Promise<void> {
    const dto = {
        timeIn: toLocalISOString(timeIn),
        newTimeOut: toLocalISOString(timeOut),
    };
    await fetchAndRenew("/add_missing_attendance_checkout", "POST", JSON.stringify(dto));
}

export async function checkIn(): Promise<void> {
    await fetchAndRenew("/checkIn", "POST");
}

export async function checkOut(): Promise<void> {
    await fetchAndRenew("/checkOut", "POST");
}
