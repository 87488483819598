import React, { useEffect, useState, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    Grid,
    MenuItem,
    Select,
    Typography,
    Paper,
    Button,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
} from "@material-ui/core";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import WorkIcon from "@material-ui/icons/Work";
import WorkOffIcon from "@material-ui/icons/WorkOff";

import { Column } from "material-table";
import MaterialTable from "../components/MaterialTable";

import { friendlyDate, isToday, pad } from "../utils/date";
import { useInterval } from "../utils/hooks";

import { GlobalContext } from "./Main";
import { InvalidTokenError } from "../api/shared";
import Status from "../components/Status";
import {
    AttendanceStatus,
    UserInfo,
    fetchUserAttendanceList,
    getPersonalAttendances,
    Attendance,
    checkIn,
    checkOut,
} from "../api/attendances";
import { Resource, error, loading, success } from "../utils/resource";

const useStyles = makeStyles(theme => ({
    titleHeader: {
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },
    statusPaper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    errorPaper: {
        marginBottom: theme.spacing(2),
    },

    mobileList: {
        maxHeight: "65vh",
        overflow: "auto",
    },
}));

const attendanceDot = (attendant: boolean) => {
    const color = attendant ? "green" : "red";
    return <CircleIcon fontSize="inherit" style={{ fontSize: 12, color, marginRight: "0.3rem" }} />;
};

function renderAttendance(data: UserInfo) {
    return (
        <div>
            {attendanceDot(data.attendance === AttendanceStatus.Attendant)}
            <p style={{ display: "inline" }}>{data.reason}</p>
        </div>
    );
}

function attendanceSearch(filter: string, data: UserInfo, def: Column<UserInfo>): boolean {
    return data.reason.toLowerCase().includes(filter.toLowerCase());
}

type Filter = "all" | "attendant" | "absent";
function filterData(filter: Filter, user: UserInfo): boolean {
    return (
        filter === "all" ||
        (filter === "attendant" && user.attendance === AttendanceStatus.Attendant) ||
        (filter === "absent" && user.attendance === AttendanceStatus.Absent)
    );
}

const Attendances = () => {
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));
    const md = useMediaQuery(theme.breakpoints.only("md"));
    const classes = useStyles();
    const { showInvalidTokenModal } = useContext(GlobalContext);

    const [filter, setFilter] = useState<Filter>("all");

    const [users, setUsers] = useState<Resource<UserInfo[]>>(loading());
    const [yourAttendances, setAttendances] = useState<Resource<Attendance[]>>(loading());

    const [checkInOutButtonDisabled, setCheckInOutButtonDisabled] = useState(false);

    const fetchUsers = () => fetchUserAttendanceList().then(users => setUsers(success(users)));
    const fetchSelfStatus = () =>
        getPersonalAttendances().then(attendances => {
            setAttendances(success(attendances));
        });

    useEffect(() => {
        Promise.all([fetchUsers(), fetchSelfStatus()]).catch(e => {
            if (e instanceof InvalidTokenError) {
                showInvalidTokenModal();
            } else {
                console.error(e);
                setUsers(error());
                setAttendances(error());
            }
        });
    }, [showInvalidTokenModal]);

    const handleCheckInOut = (attendance?: Attendance) => {
        setCheckInOutButtonDisabled(true);
        const isCheckedIn = attendance !== undefined && attendance.timeOut === undefined;

        (isCheckedIn ? checkOut() : checkIn())
            .then(() => Promise.all([fetchUsers(), fetchSelfStatus()]))
            .catch(e => {
                if (e instanceof InvalidTokenError) {
                    showInvalidTokenModal();
                } else {
                    console.error(e);
                    setUsers(error());
                    setAttendances(error());
                }
            })
            .finally(() => setCheckInOutButtonDisabled(false));
    };

    useInterval(() => {
        Promise.all([fetchUsers(), fetchSelfStatus()]).catch(e => {
            if (e instanceof InvalidTokenError) {
                showInvalidTokenModal();
            } else {
                console.error(e);
                setUsers(error());
                setAttendances(error());
            }
        });
    }, 1000 * 60);

    // `attendance` is the latest attendance for today
    function renderSelfStatus(attendances: Attendance[]) {
        const attendance = attendances[attendances.length - 1];
        const checkedIn = attendance !== undefined && attendance.timeOut === undefined;
        const hasLoaded = yourAttendances.status === "success";

        let durationMinutes = 0;

        const todaysAttendances = attendances.filter(attendance => isToday(attendance.timeIn));
        for (const atd of todaysAttendances) {
            if (atd.timeOut === undefined) {
                durationMinutes += Math.max(0, Math.floor((new Date().getTime() - atd.timeIn.getTime()) / 1000 / 60));
            } else {
                durationMinutes += Math.floor((atd.timeOut.getTime() - atd.timeIn.getTime()) / 1000 / 60);
            }
        }

        const hours = Math.floor(durationMinutes / 60);
        const minutes = durationMinutes % 60;

        const duration = `${pad(hours)}:${pad(minutes)}`;

        let checkedInOutInfo;

        if (attendance !== undefined) {
            if (attendance.timeOut === undefined) {
                // checked in
                checkedInOutInfo = friendlyDate(attendance.timeIn);
            } else if (attendance.timeOut.getDay() === new Date().getDay()) {
                // was there today
                checkedInOutInfo = friendlyDate(attendance.timeOut);
            } else {
                checkedInOutInfo = friendlyDate(attendance.timeOut);
            }
        } else {
            checkedInOutInfo = "---";
        }

        const statusMsg =
            yourAttendances.status === "error"
                ? "Error"
                : yourAttendances.status === "loading"
                ? "Lädt..."
                : checkedIn
                ? "Anwesend"
                : "Abwesend";

        const statusMsgColor =
            yourAttendances.status === "error"
                ? "red"
                : yourAttendances.status === "loading"
                ? "gray"
                : checkedIn
                ? "green"
                : "red";

        return (
            <div className={classes.statusPaper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm>
                        <Typography color="secondary">Status</Typography>
                        <div style={smUp ? { display: "flex", alignItems: "center" } : {}}>
                            <Typography variant="h6" color="inherit" style={{ color: statusMsgColor }}>
                                {statusMsg}
                            </Typography>
                            {hasLoaded && (
                                <Button
                                    style={{
                                        marginLeft: smUp ? "10px" : undefined,
                                        backgroundColor: checkedIn ? "#dd0011" : "#4CAF50",
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                    variant="contained"
                                    color="inherit"
                                    endIcon={!md ? checkedIn ? <WorkOffIcon /> : <WorkIcon /> : undefined}
                                    disabled={checkInOutButtonDisabled}
                                    onClick={() => handleCheckInOut(attendance)}
                                >
                                    {checkedIn ? "Auschecken" : "Einchecken"}
                                </Button>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm>
                        <Typography color="secondary" noWrap>
                            {checkedIn ? "Eingecheckt" : "Ausgecheckt"}
                        </Typography>
                        <Typography variant="h6" noWrap>
                            {checkedInOutInfo}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm>
                        <Typography color="secondary" noWrap>
                            Heutige Anwesenheit
                        </Typography>
                        <Typography variant="h6">{duration}</Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }

    function renderAttendanceFilter() {
        return (
            <Select value={filter} onChange={val => setFilter(val.target.value as Filter)}>
                <MenuItem value="all">
                    <em>Alle Nutzer</em>
                </MenuItem>
                <MenuItem value="attendant">Nur anwesend</MenuItem>
                <MenuItem value="absent">Nur abwesend</MenuItem>
            </Select>
        );
    }

    function renderDesktopList() {
        return (
            <MaterialTable
                columns={[
                    {
                        title: "Anwesenheit",
                        render: renderAttendance,
                        customFilterAndSearch: attendanceSearch,
                        sorting: false,
                    },
                    { title: "Personalnr.", field: "personalnummer" },
                    { title: "Vorname", field: "firstName" },
                    { title: "Nachname", field: "lastName", defaultSort: "asc" },
                ]}
                status={users.status}
                data={users.status === "success" ? users.value.filter(user => filterData(filter, user)) : []}
                title={renderAttendanceFilter()}
            />
        );
    }

    function renderMobileList() {
        if (users.status !== "success") return;

        const listItem = (user: UserInfo, idx: number) => {
            const attendant = user.attendance === AttendanceStatus.Attendant;
            const primary = `${user.firstName} ${user.lastName}`;

            let secondary;
            if (!attendant && user.reason !== "Abwesend") secondary = user.reason;

            return (
                <ListItem key={idx}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                        {attendanceDot(attendant)}
                        <ListItemText primary={primary} secondary={secondary} />
                    </div>
                </ListItem>
            );
        };

        return (
            <Paper>
                <div style={{ color: "red", marginLeft: "1rem", paddingTop: "0.5rem" }}>{renderAttendanceFilter()}</div>
                <List className={classes.mobileList}>
                    {users.value.filter(user => filterData(filter, user)).map(listItem)}
                </List>
            </Paper>
        );
    }

    return (
        <>
            <Typography className={classes.titleHeader} component="h1" variant="h3" color="secondary">
                Anwesenheit
            </Typography>
            {(yourAttendances.status === "error" || users.status === "error") && (
                <Paper className={classes.errorPaper}>
                    <Status type="error" />
                </Paper>
            )}
            <Paper>{renderSelfStatus(yourAttendances.status === "success" ? yourAttendances.value : [])}</Paper>

            {smUp ? renderDesktopList() : renderMobileList()}
        </>
    );
};

export default Attendances;
