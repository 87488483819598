import React, { useCallback, useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    TextField,
    Typography,
    ListSubheader,
} from "@material-ui/core";
import {
    KeyboardDateTimePicker,
    KeyboardDatePicker,
    KeyboardDateTimePickerProps,
    KeyboardDatePickerProps,
} from "@material-ui/pickers";
import ConfirmIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlusIcon from "@material-ui/icons/Add";

import ModalMsg from "../components/ModalMsg";
import Status from "../components/Status";

import { GlobalContext } from "./Main";
import { dateIsValid, dateToString } from "../utils/date";
import { Resource, error, loading, success } from "../utils/resource";
import { InvalidTokenError } from "../api/shared";
import {
    PlannedAbsence,
    addPlannedAbsence,
    deletePlannedAbsence,
    getPlannedAbsences,
    updatePlannedAbsence,
} from "../api/plannedAbsence";

const useStyles = makeStyles(theme => ({
    titleHeader: {
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },

    editBox: {
        display: "flex",
        flexDirection: "column",
    },
    editDate: {
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    editDateTo: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(1),
        },
    },
}));

const untimifyDate = (date: Date | null) => (date ? new Date(date.toDateString()) : null);

const DateMaybeTimePicker = (props: KeyboardDateTimePickerProps & KeyboardDatePickerProps & { dateOnly?: boolean }) => {
    const { dateOnly, onChange, ...pickerProps } = props;
    // material-pickers will include the current time event though it is a _KeyboardDatePicker_, so we delete the time part
    if (dateOnly) {
        let maxDate = new Date(new Date().getFullYear() + 2, 0, 0);

        return (
            <KeyboardDatePicker
                onChange={date => onChange(untimifyDate(date))}
                {...pickerProps}
                maxDate={maxDate}
                format="dd.MM.yyyy"
            />
        );
    } else {
        return <KeyboardDateTimePicker onChange={onChange} {...pickerProps} format="dd.MM.yyyy HH:mm" />;
    }
};

function validateDates(start: Date | null, end: Date | null, reason: String): boolean {
    if (!start || !end) return false;
    if (reason.includes("Urlaub")) {
        if (start > end) return false;
    } else if (start >= end) return false;
    return true;
}

const ERR_MSG_MISSING_FIELD = "Nicht alle Eingabedaten wurden angegeben.";
const ERR_START_AFTER_OR_EQUALS_END = "Das Ende der Abwesenheit sollte nach dem Anfang liegen.";
const ERR_START_AFTER_END = "Das Ende der Abwesenheit sollte nicht vor dem Anfang liegen";
const ERR_ALREADY_THERE = "Es existiert bereits eine Abwesenheit für diesen Zeitrahmen.";
const ERR_VACATION_DAYS_OVER = "Die verbleibenden Urlaubstage reichen nicht aus, um diesen Urlaub anzulegen.";
const ERR_HOLIDAYS_SEPARATE = "Urlaubstage werden in der unteren Liste verwaltet.";
const ERR_HOLIDAY_OVERLAP = "In diesem Zeitrahmen befindet sich bereits ein Urlaub.";

interface AbsenceListItemProps {
    isVacation?: boolean;
    isAdmin: boolean;
    personalnummer?: number;
    refetch: () => Promise<void>;
    setError: () => void;
    setModalState(modalState?: ModalState): void;
}

const AddAbsenceItem: React.FC<AbsenceListItemProps> = ({
    isAdmin,
    personalnummer,
    refetch,
    setError,
    setModalState,
    isVacation,
}) => {
    const classes = useStyles();
    const { showInvalidTokenModal } = useContext(GlobalContext);

    const [edit, setEdit] = useState(false);
    const [reason, setReason] = useState<string | undefined>(isVacation ? "Urlaub" : "");
    const [start, setStart] = useState<Date | null>(null);
    const [end, setEnd] = useState<Date | null>(null);

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const clear = () => {
        setReason(isVacation ? "Urlaub" : "");
        setStart(null);
        setEnd(null);
    };

    const addItem = async (confirm?: boolean) => {
        if (!start || !end || !reason) {
            setModalState({ type: "invalidForm", msg: ERR_MSG_MISSING_FIELD });
            return;
        }
        if (!validateDates(start, end, reason)) {
            if (reason.includes("Urlaub")) {
                setModalState({ type: "invalidForm", msg: ERR_START_AFTER_END });
            } else {
                setModalState({ type: "invalidForm", msg: ERR_START_AFTER_OR_EQUALS_END });
            }
            return;
        }
        if (!isVacation && reason.toLowerCase().includes("urlaub")) {
            setModalState({ type: "invalidForm", msg: ERR_HOLIDAYS_SEPARATE });
            return;
        }

        setSubmitDisabled(true);
        try {
            const overdrawn = await addPlannedAbsence(start, end, reason, personalnummer, confirm);
            if (overdrawn !== undefined) {
                setModalState({
                    type: "overdrawn",
                    overdrawn,
                    continue: async () => {
                        await addItem(true);
                        setModalState(undefined);
                    },
                });
            } else {
                clear();
                setEdit(false);
                await refetch();
            }
        } catch (e) {
            if (e instanceof InvalidTokenError) {
                showInvalidTokenModal();
            } else if (e.message === "Given absence already exists") {
                setModalState({ type: "invalidForm", msg: ERR_ALREADY_THERE });
            } else if (e.message === "There is already a holiday in that time frame") {
                setModalState({ type: "invalidForm", msg: ERR_HOLIDAY_OVERLAP });
            } else if (e.message === "Reached the maximum of holidays and insufficient permissions to add more") {
                setModalState({ type: "invalidForm", msg: ERR_VACATION_DAYS_OVER });
            } else {
                console.error(e);
                setError();
            }
        } finally {
            setSubmitDisabled(false);
        }
    };

    const handleEditToggle = () => {
        if (edit) {
            // if no data was set (reason gets set by default for isVacation) abort
            if (!start && !end && (!reason || isVacation)) {
                setEdit(false);
                return;
            }

            addItem();
        } else {
            setEdit(true);
        }
    };

    return (
        // fields for adding absences
        <ListItem>
            <ListItemIcon>
                <IconButton disabled={submitDisabled} onClick={handleEditToggle} edge="end">
                    {edit ? <ConfirmIcon /> : <PlusIcon />}
                </IconButton>
            </ListItemIcon>
            {edit && (
                <div className={classes.editBox}>
                    <div>
                        <DateMaybeTimePicker
                            dateOnly={isVacation}
                            className={classes.editDate}
                            variant="inline"
                            ampm={false}
                            label="Von"
                            value={start}
                            onChange={date => {
                                setStart(date);
                                if (!end && date !== null && dateIsValid(date)) setEnd(date);
                            }}
                        />
                        <DateMaybeTimePicker
                            dateOnly={isVacation}
                            className={`${classes.editDate} ${classes.editDateTo}`}
                            variant="inline"
                            ampm={false}
                            label="Bis"
                            value={end}
                            onChange={setEnd}
                        />
                    </div>
                    <TextField
                        disabled={isVacation}
                        defaultValue={reason}
                        placeholder="Grund für die Abwesenheit"
                        onChange={e => setReason(e.target.value)}
                    />
                </div>
            )}
        </ListItem>
    );
};

const AbsenceListItem: React.FC<AbsenceListItemProps & { val: PlannedAbsence }> = ({
    val,
    isAdmin,
    personalnummer,
    refetch,
    setError,
    setModalState,
    isVacation,
}) => {
    const classes = useStyles();
    const { showInvalidTokenModal } = useContext(GlobalContext);

    const [edit, setEdit] = useState(false);
    const [reason, setReason] = useState<string>(val.reason);
    const [start, setStart] = useState<Date | null>(val.start);
    const [end, setEnd] = useState<Date | null>(val.end);

    const [deleteDisabled, setDeleteDisabled] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const isEditable = () => isAdmin || val.start >= new Date();

    const update = async (val: PlannedAbsence, confirm?: boolean) => {
        if (!start || !end || !reason) {
            setModalState({ type: "invalidForm", msg: ERR_MSG_MISSING_FIELD });
            return;
        }
        if (!validateDates(start, end, reason)) {
            if (reason.includes("Urlaub")) {
                setModalState({ type: "invalidForm", msg: ERR_START_AFTER_END });
            } else {
                setModalState({ type: "invalidForm", msg: ERR_START_AFTER_OR_EQUALS_END });
            }
            return;
        }
        if (!isVacation && reason.toLowerCase().includes("urlaub")) {
            setModalState({ type: "invalidForm", msg: ERR_HOLIDAYS_SEPARATE });
            return;
        }

        const options = {
            oldStart: val.start,
            oldEnd: val.end,
            newStart: start.getTime() !== val.start.getTime() ? start : undefined,
            newEnd: end.getTime() !== val.end.getTime() ? end : undefined,
            newReason: reason !== val.reason ? reason : undefined,
            personalnummer,
        };

        setSubmitDisabled(true);
        try {
            const overdrawn = await updatePlannedAbsence(options, confirm);
            if (overdrawn !== undefined) {
                setModalState({
                    type: "overdrawn",
                    overdrawn,
                    continue: async () => {
                        await update(val, true);
                        setModalState(undefined);
                    },
                });
            } else {
                setEdit(false);
                await refetch();
            }
        } catch (e) {
            if (e instanceof InvalidTokenError) {
                showInvalidTokenModal();
            } else if (e.message === "Given absence already exists") {
                setModalState({ type: "invalidForm", msg: ERR_ALREADY_THERE });
            } else if (e.message === "There is already a holiday in that time frame") {
                setModalState({ type: "invalidForm", msg: ERR_HOLIDAY_OVERLAP });
            } else if (e.message === "Reached the maximum of holidays and insufficient permissions to add more") {
                setModalState({ type: "invalidForm", msg: ERR_VACATION_DAYS_OVER });
            } else {
                console.error(e);
                setError();
            }
        } finally {
            setSubmitDisabled(false);
        }
    };

    const deleteItem = (val: PlannedAbsence) => {
        setDeleteDisabled(true);
        deletePlannedAbsence(val.start, val.end, personalnummer)
            .then(refetch)
            .catch(e => {
                if (e instanceof InvalidTokenError) {
                    showInvalidTokenModal();
                } else {
                    console.error(e);
                    setError();
                }
            });
    };

    const handleEditToggle = () => {
        if (edit) {
            // if no data was set (reason gets set by default for isVacation) abort
            if (!start && !end && (!reason || isVacation)) {
                setEdit(false);
                return;
            }

            update(val);
        } else {
            setEdit(true);
        }
    };

    return (
        // fields for changing and labels for displaying absences
        <ListItem>
            <ListItemIcon>
                <IconButton disabled={submitDisabled || !isEditable()} onClick={handleEditToggle} edge="end">
                    {edit ? <ConfirmIcon /> : <EditIcon />}
                </IconButton>
            </ListItemIcon>
            {edit ? (
                <div className={classes.editBox}>
                    <div>
                        <DateMaybeTimePicker
                            dateOnly={isVacation}
                            className={classes.editDate}
                            variant="inline"
                            ampm={false}
                            label="Von"
                            value={start}
                            onChange={setStart}
                        />
                        <DateMaybeTimePicker
                            dateOnly={isVacation}
                            className={`${classes.editDate} ${classes.editDateTo}`}
                            variant="inline"
                            ampm={false}
                            label="Bis"
                            value={end}
                            onChange={setEnd}
                        />
                    </div>
                    <TextField disabled={isVacation} defaultValue={reason} onChange={e => setReason(e.target.value)} />
                </div>
            ) : (
                <ListItemText
                    primary={`${dateToString(val.start)} bis ${dateToString(val.end)}`}
                    secondary={val.reason}
                />
            )}
            <ListItemSecondaryAction>
                <IconButton
                    disabled={deleteDisabled || !isEditable()}
                    onClick={() => deleteItem(val)}
                    edge="end"
                    aria-label="delete"
                >
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

function isVacation(value: PlannedAbsence): boolean {
    return value.reason.toLowerCase().includes("urlaub");
}

type ModalState =
    | { type: "invalidForm"; msg: string }
    | { type: "overdrawn"; overdrawn: number; continue(): Promise<void> };

interface AbsenceListProps {
    personalnummer?: number;
}
export const AbsenceList: React.FC<AbsenceListProps> = ({ personalnummer }) => {
    const { claims, showInvalidTokenModal } = useContext(GlobalContext);
    const isAdmin = claims.roleID === 1;

    const LOAD_MORE_INTERVAL = 5;

    const [plannedAbsences, setPlannedAbsences] = useState<Resource<PlannedAbsence[]>>(loading());
    const [plannedAbsenceCount, setPlannedAbsenceCount] = useState(LOAD_MORE_INTERVAL);
    const [holidayCount, setHolidayCount] = useState(LOAD_MORE_INTERVAL);
    const increaseAbsenceCount = () => setPlannedAbsenceCount(plannedAbsenceCount + LOAD_MORE_INTERVAL);
    const increaseHolidayCount = () => setHolidayCount(holidayCount + LOAD_MORE_INTERVAL);

    const [modal, setModalState] = useState<ModalState | undefined>(undefined);

    const fetchAbsences = useCallback(async () => {
        try {
            const plannedAbsences = await getPlannedAbsences(personalnummer);
            setPlannedAbsences(success(plannedAbsences.reverse()));
        } catch (e) {
            if (e instanceof InvalidTokenError) {
                showInvalidTokenModal();
            } else {
                console.error(e);
                setPlannedAbsences(error());
            }
        }
    }, [showInvalidTokenModal, personalnummer]);

    useEffect(() => {
        fetchAbsences();
    }, [fetchAbsences]);

    const renderModal = () => {
        switch (modal?.type) {
            case "invalidForm":
                return (
                    <ModalMsg
                        isOpen={modal !== undefined}
                        close={() => setModalState(undefined)}
                        allowClose={true}
                        title="Fehler!"
                        description={modal.msg}
                    />
                );
            case "overdrawn":
                return (
                    <ModalMsg
                        isOpen={modal !== undefined}
                        close={() => setModalState(undefined)}
                        allowClose={true}
                        title="Achtung!"
                        description={`Sie sind im Begriff, die Urlaubstage um ${modal.overdrawn} zu überziehen!`}
                    >
                        <Button onClick={modal.continue} size="small" variant="contained">
                            Fortfahren
                        </Button>
                    </ModalMsg>
                );
        }
    };

    const all = plannedAbsences.status === "success" ? plannedAbsences.value : [];
    const nonHoliday = all.filter(a => !isVacation(a));
    const holiday = all.filter(isVacation);

    const renderShowMoreItem = (increase: () => void) => (
        <ListItem button onClick={increase}>
            <ListItemText inset primary="Mehr anzeigen..." primaryTypographyProps={{ style: { fontWeight: "bold" } }} />
        </ListItem>
    );

    return (
        <div>
            {renderModal()}
            {plannedAbsences.status === "loading" && <Status type="loading" />}
            {plannedAbsences.status === "error" && <Status type="error" />}
            {plannedAbsences.status === "success" && (
                <List>
                    <ListSubheader>Abwesenheiten</ListSubheader>
                    {nonHoliday.slice(0, plannedAbsenceCount).map(val => (
                        <AbsenceListItem
                            key={`${val.start.getTime()}${val.end.getTime()}`}
                            val={val}
                            isAdmin={isAdmin}
                            personalnummer={personalnummer}
                            refetch={fetchAbsences}
                            setModalState={setModalState}
                            setError={() => setPlannedAbsences(error())}
                        />
                    ))}
                    {nonHoliday.length > plannedAbsenceCount && renderShowMoreItem(increaseAbsenceCount)}
                    <AddAbsenceItem
                        isAdmin={isAdmin}
                        personalnummer={personalnummer}
                        refetch={fetchAbsences}
                        setModalState={setModalState}
                        setError={() => setPlannedAbsences(error())}
                    />

                    <ListSubheader>Urlaube</ListSubheader>
                    {holiday.slice(0, holidayCount).map(val => (
                        <AbsenceListItem
                            key={`${val.start.getTime()}${val.end.getTime()}`}
                            val={val}
                            isAdmin={isAdmin}
                            personalnummer={personalnummer}
                            refetch={fetchAbsences}
                            setModalState={setModalState}
                            setError={() => setPlannedAbsences(error())}
                            isVacation
                        />
                    ))}
                    {holiday.length > holidayCount && renderShowMoreItem(increaseHolidayCount)}
                    <AddAbsenceItem
                        isAdmin={isAdmin}
                        personalnummer={personalnummer}
                        refetch={fetchAbsences}
                        setModalState={setModalState}
                        setError={() => setPlannedAbsences(error())}
                        isVacation
                    />
                </List>
            )}
        </div>
    );
};

const AbsencePlanner = () => {
    const classes = useStyles();

    return (
        <>
            <Typography className={classes.titleHeader} component="h1" variant="h3" color="secondary">
                Geplante Abwesenheiten
            </Typography>

            <Paper>
                <AbsenceList />
            </Paper>
        </>
    );
};

export default AbsencePlanner;
